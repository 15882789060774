import { IncomingMessage, ServerResponse } from 'http'

import Cookies from 'cookies'
import JsCookies from 'js-cookie'

import { AuthToken } from 'tribe-api'

const ACCESS_TOKEN = 'accessToken'
const REFRESH_TOKEN = 'refreshToken'
const NETWORK_REDIRECT_URL_COOKIE = 'networkRedirectUrl'
const AccessTokenMaxAge = 30 * 24 * 60 * 60 * 1000 // one month
const RefreshTokenMaxAge = 12 * 30 * 24 * 60 * 60 * 1000 // one year

export const setSSRAuthCookies = ({
  accessToken,
  refreshToken,
  req,
  res,
}: {
  req: IncomingMessage
  res: ServerResponse
  accessToken: AuthToken['accessToken']
  refreshToken: AuthToken['refreshToken']
}): void => {
  const cookies = new Cookies(req, res)
  cookies.set(ACCESS_TOKEN, accessToken, {
    httpOnly: false,
    maxAge: AccessTokenMaxAge,
  })
  cookies.set(REFRESH_TOKEN, refreshToken, {
    httpOnly: false,
    maxAge: RefreshTokenMaxAge,
  })
}

export const setAuthCookies = ({
  accessToken,
  refreshToken,
}: {
  accessToken?: AuthToken['accessToken']
  refreshToken?: AuthToken['refreshToken']
}) => {
  if (accessToken) {
    JsCookies.set(ACCESS_TOKEN, accessToken, {
      httpOnly: false,
    })
  }
  if (refreshToken) {
    JsCookies.set(REFRESH_TOKEN, refreshToken, {
      httpOnly: false,
    })
  }
}

export const setRedirectUrlCookie = (redirectUrl: string): void => {
  JsCookies.set(NETWORK_REDIRECT_URL_COOKIE, redirectUrl, {
    httpOnly: false,
    expires: new Date(new Date().getTime() + 2 * 60 * 1000), // 2 minutes
  })
}

export const getRedirectUrlCookie = (): string | undefined => {
  return JsCookies.get(NETWORK_REDIRECT_URL_COOKIE)
}

export const removeRedirectUrlCookie = (): void => {
  JsCookies.remove(NETWORK_REDIRECT_URL_COOKIE)
}

export const resetSSRAuthCookies = ({
  req,
  res,
}: {
  req: IncomingMessage
  res: ServerResponse
}) => {
  const cookies = new Cookies(req, res)
  cookies.set(ACCESS_TOKEN)
  cookies.set(REFRESH_TOKEN)
}

export const resetAuthCookies = () => {
  /*
      IMPORTANT! When deleting a cookie and you're not relying on the default attributes,
      you must pass the exact same path and domain attributes that were used to set the cookie
    */
  JsCookies.remove(ACCESS_TOKEN)
  JsCookies.remove(REFRESH_TOKEN)
}

export const getNetworkSSRProps = (
  req: IncomingMessage,
  res: ServerResponse,
) => {
  const successState = {
    props: {
      seo: null, // should be here since its not wrapped with auth function
      namespacesRequired: ['common'],
      mustUseSupportToken: true,
    },
  }
  const url = req?.url
  const isFirstServerCall = url?.indexOf('/_next/data/') === -1

  if (!isFirstServerCall) return successState
  const cookies = new Cookies(req, res)
  const accessToken = cookies.get(ACCESS_TOKEN)

  // User has already logged in into the support panel.
  if (accessToken) {
    return successState
  }

  res?.writeHead(302, {
    Location: `/auth/sp-login?redirectUrl=${url}`,
  })

  res?.end()
  return {
    props: {
      seo: null,
    },
  }
}
